.modal-overlay{width: 100%;height: 100%;background: #0000007a;display: flex;justify-content: center;align-items: flex-start;position: inherit;overflow-y: auto;}
.modal-cart{top:0;position:fixed;z-index:1300;top:0;left:0;overflow-y:auto;width:100%;height:100%;}
.modal_content{margin:14px 0 4px 0;position: relative;}
.modal_wrap{font-family:"GilroyGilroyLight";}
.modal_c{background:white;border-radius:16px;padding: 18px 23px;box-shadow:0 8px 20px rgb(117 115 111 / 20%);animation:modal_theme_normal_visible__content 0.3s;margin-top: 60px;    margin-bottom: 60px;}
.modal-header-title{font-size:22px;flex:1;overflow-wrap: anywhere;font-family: "GilroyExtraBold";}
.modal_header{display:flex;align-items:center;border-bottom:1px solid #e4ecfd;padding: 0 0 13px 0;}
.modal_footer{margin-top:15px;border-top:1px solid #e4ecfd;padding:15px 0 0 0;display:flex;align-items:center;}
.modal_cart_close{width:24px;height:24px;padding:0;border:none;cursor:pointer;-ms-flex-item-align:start;-ms-grid-row-align:start;align-self:start;-ms-flex-negative:0;flex-shrink:0;background:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTAiIGZpbGw9IiMwMDAiIGZpbGwtb3BhY2l0eT0iLjIiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjIwNyA3Ljc5M2ExIDEgMCAxMC0xLjQxNCAxLjQxNEwxMC41ODYgMTJsLTIuNzkzIDIuNzkzYTEgMSAwIDAwMS40MTQgMS40MTRMMTIgMTMuNDE0bDIuNzkzIDIuNzkzYTEgMSAwIDAwMS40MTQtMS40MTRMMTMuNDE0IDEybDIuNzkzLTIuNzkzYTEgMSAwIDAwLTEuNDE0LTEuNDE0TDEyIDEwLjU4NiA5LjIwNyA3Ljc5M3oiIGZpbGw9IiNmZmYiLz48L3N2Zz4=) 50% no-repeat;}

@keyframes modal_theme_normal_visible__content {
    0%{transform:scale(.75)}to{transform:scale(1)}
}

.form-input, .form-text-input, .form-date-field, .form-single-select {
    width: 100%;
    height: 56px;
    padding: 5px 10px;
    border: 1px solid #c7c7c7;
    font-size: 17px;
    background: white;
    margin: 11px 0;
    border-radius: 10px;
}

.form-text-input:focus {
    border: 1px solid #4e4e4e;
}

.hintsInput {
    color: #606060;
    font-family: "GilroyGilroyLight";
}

.RatingFormModal {
    margin: 8px 0;
}

.RatingFormModal > .hintsInput {
    margin-bottom: 10px;
}

.error-wrap {
    margin-bottom: 12px;
}

.error-wrap > div {
    color: #ff0000c9;
    margin-bottom: 8px;
    font-family: "GilroyGilroyLight";
}

.ManagerModalInfo {
    padding: 9px 12px;
    background: #f5f5f5;
    border-radius: 6px;
    margin-bottom: 14px;
}

.BOX-SATUS-error {
    background: #ff002345;
    padding: 11px 9px;
    border-radius: 10px;
}

.BOX-SATUS-success {
    background: #00ff5a45;
    padding: 11px 9px;
    border-radius: 10px;
}

.BOX-SATUS-warning {
    background: #ff990045;
    padding: 11px 9px;
    border-radius: 10px;
}

@keyframes Animation {
  0% {opacity: 0;}
  100% {opacity: 1;}
}