.WrapBoxSplit {
	display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 0 24px;
}

.InfoBoxBenefit {
	font-family: "GilroyGilroyLight";
    border: 2px solid #cfcfcf;
    border-radius: 16px;
    padding: 14px 22px;
    margin: 16px 0;
    display: flex;
    align-items: center;
}

.InfoBoxBenefit__TextWrap {
	font-size: 19px;
    color: #6c6c6c;
    text-align: center;
    margin: 0 50px;
}

.TitleBoxSplit {
	font-family: "GilroyExtraBold";
    font-size: 35px;
    text-align: center;
}

.SubTitleBoxSplit {
	font-family: "GilroyGilroyLight";
	font-size: 24px;
	text-align: center;
	margin: 25px 0;
}

.WrapForm {
	width: 100%;
}

.FormInput {
	width: 100%;
    height: 56px;
    padding: 5px 18px;
    border: unset;
    font-size: 19px;
    background: whitesmoke;
    margin: 11px 0;
    border-radius: 18px;
    font-family: "GilroyGilroyLight";
}

.RulesParticipation {
	font-family: "GilroyGilroyLight";
	color: #6c6c6c;
	font-size: 14px;
}

.ButtonSend {
	background: black;
    padding: 17px 0;
    text-align: center;
    border-radius: 22px;
    font-family: "GilroyExtraBold";
    color: white;
    margin: 21px 0;
    font-size: 19px;
    cursor: pointer;
}

.HeaderImage {
	width: 333px;
}

.ClientBonusesInfo {
    padding: 11px 11px;
    border-radius: 10px;
    font-family: "GilroyGilroyLight";
    border: 1px solid #cfcfcf;
}

.dataReqWrap {
    margin: 16px 0;
}

@media (max-width: 785px) {
    .InfoBoxBenefit {
        flex-direction: column;
    }
    .InfoBoxBenefit__TextWrap {
        margin: 0 0px;
        margin-bottom: 18px;
    }

    .SubTitleBoxSplit {
        font-size: 17px;
    }

    .TitleBoxSplit {
        font-size: 26px;
    }

    .WrapBoxSplit {
        padding: 0 0px;
    }
}
