@media (max-width: 992px) {
    .ModuleHeader-WrapProjectTitle {
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .ModuleHeader-ProjectTitle {
        margin-bottom: 23px;
    }
}

@media (max-width: 500px) {
    .ModuleHeader-InfoBoxProject-Desc {
        font-size: 20px!important;
    }
}

@media (max-width: 850px) {
    .ReviewItem-DetailBox {
        display: block!important;
    }
    
    .ReviewItem-RatingBoxWrap {
        margin-top: 14px;
    }
}


.ModuleHeader-WrapProjectTitle {
    font-family: "GilroyExtraBold";
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.ModuleHeader-ProjectTitle {
    font-size: 50px;
}

.ModuleHeader-InfoBoxProject-Desc {
    font-family: "GilroyGilroyLight";
    font-size: 25px;
    white-space: pre-line;
    color: rgba(0,0,0,.56);
}

.ModuleHeader-InfoBoxProject-AdditionalButton-SendRequest {
    color: #fff;
    margin-right: 22px;
    background: #2e2e2e;
    border-radius: 18px;
    cursor: pointer;
    font-family: GilroyGilroyLight;
    padding: 9px 15px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
}

.ModuleHeader-InfoBoxProject-AdditionalButton-LeaveReview {
    width: fit-content;
    background: white;
    color: black;
    padding: 9px 15px;
    border-radius: 18px;
    font-family: "GilroyGilroyLight";
    box-shadow: inset 0px 0px 0px 1px rgb(0 0 0);
    cursor: pointer;
    text-align: center;
}

.ModuleHeader-InfoBoxProject {
    margin-bottom: 24px;
}

.ModuleHeader-InfoBoxProject-AdditionalButton {
    display: flex;
    align-items: center;
    font-size: 19px;
}

.ModuleHeader-ProjectRating {
    background: #ffffff;
    padding: 8px 14px 8px 7px;
    box-shadow: 0px 4px 15px -7px rgb(34 60 80 / 23%);
    font-family: "GilroyGilroyLight";
    width: fit-content;
    border-radius: 14px;
    display: flex;
    align-items: center;
}

.ModuleHeader-ProjectRating-IconStar {
    background-image: url(../media/star.png);
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 7px;
}

.ModuleHeader-TabsAction {
    display: flex;
    font-family: "GilroyGilroyLight";
    justify-content: flex-start;
    font-size: 22px;
    color: #4e4e4e;
    align-items: center;
    overflow-x: auto;
}

.ModuleHeader-TabItemAction:after {
    border-bottom: 3px solid #a1a1a1;
    width: 100%;
    display: block;
    height: 2px;
    content: "";
    margin-top: 4px;
}

.TabItemActionActive {
    font-family: "GilroyExtraBold";
    color: black;
}

.TabItemActionActive:after {
    border-bottom: 3px solid #000000;
}

.ModuleHeader-TabItemAction {
    cursor: pointer;
    margin-right: 32px;
}

@keyframes HeaderModuleInfoAni {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.ModuleHeader-ToolBoxProject {
    opacity: 0;
    animation: HeaderModuleInfoAni 0.7s forwards;
}

img.ModuleProject-PhotoGallery-ItemPhoto {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.ModuleProject-PhotoGalleryWrap {
    margin-top: 33px;
}

.ModuleProject-PhotoGallery-ItemPhoto {
    position: relative;
}

.ModuleProject-PhotoGallery-CustomPhotoWrap {
    background: white;
    font-family: "GilroyGilroyLight";
    padding: 13px 18px;
    border-radius: 8px;
}

.ModuleProject-PhotoGallery-ItemPhoto > img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.ReviewItem {
    font-family: "GilroyGilroyLight";
    padding: 13px 15px;
    box-shadow: 0px 4px 15px -7px rgb(34 60 80 / 23%);
    margin-bottom: 16px;
}

.ReviewItem-ClientName {
    font-family: "GilroyExtraBold";
    color: black;
    font-size: 23px;
}

.ReviewItem-DetailBoxWrap {
    display: flex;
}

.ReviewItem-DetailBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ReviewItem-DetailBox-Item {
    margin-right: 17px;
    background: #00000014;
    color: #0000008a;
    padding: 7px 14px;
    border-radius: 12px;
}

.ReviewItem-ClientName-DateReview {
    display: flex;
    justify-content: space-between;
}

.ReviewItem-DateReview {
    color: #00000080;
}

.ReviewItem-ReviewFull {
    margin: 11px 0;
}

.ModuleProject-ReviewsWrap {
    margin-top: 33px;
}

.BorderReview {
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0px 4px 15px -7px rgb(34 60 80 / 39%);
}

span.ReviewTitleBox {
    font-family: "GilroyExtraBold";
}

.ModuleProject-Conditions {
    margin-top: 33px;
    font-family: "GilroyGilroyLight";
    font-size: 18px;
    white-space: pre-line;
}

.ModuleHeader-ProjectRating-CityProject {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ModuleHeader-CityProject {
    font-family: "GilroyGilroyLight";
    font-size: 22px;
    color: #ffffff;
    background: black;
    padding: 6px 16px;
    border-radius: 16px;
    background-color: #030301;
    background-image: linear-gradient(45deg, #030301 0%, #FF5ACD 100%);
}

.ReviewPreBox {
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ReviewButtonShowFullBox {
    color: #ff8c62;
    font-family: "GilroyExtraBold";
    margin-top: 11px;
}

.ReviewBoxInfoCustomPhoto {
    margin-bottom: 7px;
}

.ModuleProject-Conditions_StandardConditions {
    background-color: #ebe4dd5c;
    border-radius: 8px;
    padding: 13px 13px;
    color: #ba9c80;
    font-family: "GilroyExtraBold";
    margin-bottom: 17px;
    font-size: 19px;
}

.ModuleProject-Conditions_StandardConditions-Icon {
    width: 22px;
    height: 22px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 17px;
    margin-left: 5px;
}

.ModuleProject-Conditions_StandardConditions-ITEM {
    display: flex;
    flex-direction: row;
    align-items: center;
}