body {
    background-color: #dfecff00;
}

.HeaderNameCompany {
    color: #141414;
    margin-bottom: 20px;
    font-size: 40px;
}

.WrapRootCityPage {
    margin-top: 34px;
    margin-bottom: 34px;
    padding: 0 20px;
    opacity: 0;
    animation: SmoothAppearance 0.5s forwards;
}

.ChooseYourCity {
    font-family: "GilroyGilroyLight";
    font-size: 28px;
    margin-bottom: 20px;
}

input.formTextInput {
    background: #f3f3f3;
    border: unset;
    padding-left: 17px;
}

input.formTextInput:focus {
    border: unset;
}

@keyframes SmoothAppearance {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.WrapCitiesChoose {
    display: grid;
    margin-top: 21px;
}

.WrapCitiesChoose_CityItem {
    font-family: "GilroyGilroyLight";
    color: #7678ed;
    font-size: 21px;
    margin-bottom: 13px;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
}