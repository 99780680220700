@media (max-width: 785px) {
    .ModuleTitle-OurProjects-Wrap {
        flex-direction: column!important;
    }
    
    .ModuleTitle-OurProjects-CityBox {
        margin: 13px 0;
    }
}

.ModuleTitle-OurProjects {
    font-family: "GilroyExtraBold";
    font-size: 33px;
    text-align: center;
    color: #002033;
}

.OurProjects-DataCard {
    height: 392px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    position: relative;
    margin-bottom: 25px;
}

.OurProjects-DataCard-Name {
    position: absolute;
    bottom: 47px;
    color: white;
    font-family: "GilroyExtraBold";
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 38px;
    padding: 0 38px;
}

.OurProjects-DataCard-WrapRating {
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    background: white;
    padding: 4px 13px 4px 8px;
    border-radius: 11px;
    font-family: "GilroyExtraBold";
    box-shadow: 13px 6px 20px -4px rgb(34 60 80 / 23%);
}

.OurProjects-DataCard-DetailData {
    display: none;
}

.OurProjects-DataCard:hover  .OurProjects-DataCard-DetailData {
    align-items: center;
    -webkit-animation: OurProjects_DataCard_DetailData .3s forwards;
    animation: OurProjects_DataCard_DetailData .3s forwards;
    background: #f0f8ff9e;
    background: #fff;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),rgba(32,32,32,.88) 72%);
    border-radius: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: GilroyGilroyLight;
    height: 100%;
    opacity: 0;
    width: 100%;
    position: absolute;
    justify-content: flex-end;
}

@keyframes OurProjects_DataCard_DetailData {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

a.OurProjects-DataCard-DetailData-ButtonMore {
    border: 2px solid #fff;
    color: #fff;
    padding: 10px 24px;
    text-decoration: none;
    border-radius: 21px;
    position: relative;
}

.OurProjects-DataCard-DetailData-text {
    padding: 25px 22px;
    position: relative;
    text-align: center;
}

.OurProjects-DataCard-Rating-IconStar {
    background-image: url(../../../media/star.png);
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 7px;
}

.OurProjects-DataCard-Rating {
    display: flex;
    align-items: center;
}

@keyframes OurProjects-DataCardAni {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.OurProjects-DataCard {
    opacity: 0;
    animation: OurProjects-DataCardAni 1s forwards;
}

.OurProjects-DataCard-MainWrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(184deg, rgb(255 255 255 / 0%) 0%, rgb(32 32 32 / 22%) 100%);
    border-radius: 16px;
}

.ModuleTitle-OurProjects-Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
}

.ModuleTitle-OurProjects_CityBox-Name {
    display: inline-block;
    font-family: 'GilroyGilroyLight';
    font-size: 22px;
    position: relative;
    text-decoration: none;
    color: rgba(0,0,0,.56);
    background: white;
    padding: 3px 13px;
    border-radius: 18px;
}

.ModuleTitle-OurProjects_CityBox-Icon {
    width: 27px;
    height: 27px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 7px;
    margin-left: 7px;
}

.ModuleTitle-OurProjects-CityBox {
    display: flex;
    background: #ebe4dd;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 4px 4px;
    border-radius: 43px;
}

.WrapPageModule-OurProjects {
    margin-top: 53px;
}

.OurProjects-Tags-Item {
    padding: 8px 14px;
    border-radius: 22px;
    border: 2px solid #7678ed;
    color: #7678ed;
    font-family: "GilroyExtraBold";
    cursor: pointer;
    margin-right: 9px;
    margin-bottom: 9px;
}

.OurProjects-Tags-Wrap {
    display: flex;
    margin-bottom: 38px;
    width: fit-content;
    flex-wrap: wrap;
}

.OurProjects-Tags-ItemActive {
    color: #ffffff;
    background: #7678ed;
}

.NotFoundProjectsAlert {
    font-family: "GilroyGilroyLight";
    text-align: center;
    font-size: 19px;
    margin: 32px 0;
    opacity: 0;
    animation: HeaderModuleInfoAni 0.5s forwards;
}