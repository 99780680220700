@font-face{font-family:'GilroyExtraBold';src:url("../media/fonts/Gilroy-ExtraBold.otf");}
@font-face{font-family:'GilroyGilroyLight';src:url("../media/fonts/Gilroy-Light.otf");}
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

body {
    margin: 0;
    background-color: #dfecff30;
}

@media (max-width: 450px) {
    .WrapFooterModule {
        flex-direction: column;
        align-items: center;
    }

    .WrapFooterModule-Сopyright {
        margin-bottom: 15px;
    }
}

.react-loading-skeleton {
    border-radius: 1.25rem!important;
}

.UIWrapperSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    top: 0;
    animation: ani 2.5s forwards;
}

.UIWrapperSpinnerBox {
    background: white;
    padding: 18px;
    border-radius: 9px;
    -webkit-box-shadow: 0px 0px 24px -8px rgb(34 60 80 / 16%);
    -moz-box-shadow: 0px 0px 24px -8px rgba(34, 60, 80, 0.16);
    box-shadow: 0px 0px 24px -8px rgb(34 60 80 / 16%);
}

.UIIconSVGSpinner {
    width: 30px;
    height: 30px;
    -webkit-animation: SpinnerKEY .7s linear infinite;
    fill: currentcolor;
    color: #000000;
}

.SpinnerHidden {
    display: none!important;
}

@keyframes SpinnerKEY {0% {-webkit-transform: rotate(0deg); transform: rotate(0deg);}100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}}

.WrapHeaderModule {
    padding: 15px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderNameCompany {
    font-family: "GilroyExtraBold";
    font-size: 31px;
    text-decoration: none;
    color: #002033;
    width: fit-content;
    display: block;
}

.HeaderNameCompany span {
    color: #ba9c80;
}

.HeaderLogo_UserProfileIcon {
    background-image: url(../media/ProfileIcons.png);
    width: 36px;
    height: 36px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 7px;
}

/*.HeaderLogoWhatsApp {
    background-image: url(../media/icons8-whatsapp-48.png);
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 7px;
}*/

.Separator {
    box-sizing: border-box;
    background: #ebe4dd;
    height: 1px;
    margin: 0 26px;
}

.HeaderSocialBox {
    display: flex;
}

.WrapIndexPage-Slider > img {
    max-width: 100%;
    height: auto;
    margin-bottom: -20px;
    border-radius: 15px;
}

.WrapRootPage {
    /*background-color: #FBDA61;*/
    padding: 0!important;
    margin-top: 21px;
    margin-bottom: 21px;
    opacity: 0;
    animation: HeaderModuleInfoAni 0.5s forwards;
}

.WrapPageModule {
    padding: 26px;
}

.WrapFooterModule {
    font-family: "GilroyGilroyLight";
    padding: 12px 26px;
    color: darkgrey;
    display: flex;
    justify-content: space-between;
}

.WrapFooterModule-Buttons {
    display: flex;
}

.WrapFooterModule-Button {
    margin-left: 18px;
}

* {
    outline: none;
    box-sizing: border-box;
}

@keyframes SmoothAppearance {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.ManagerInfoBox {
    border-radius: 23px;
    font-family: GilroyGilroyLight;
    padding: 18px 15px;
    color: #88888d;
    background: #f5f5f5;
    opacity: 0;
    animation: HeaderModuleInfoAni 0.5s forwards;
}

.ManagerInfoBoxTitle {
    color: #000000;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 7px;
}

.ManagerInfoBox a {
    color: #7678ed;
    text-decoration: unset;
    font-weight: 700;
}

.InfoReqBox_T0 {
    background: #ff00004f;
    padding: 11px 11px;
    border-radius: 10px;
    font-family: "GilroyGilroyLight";
}

.InfoReqBox_T1 {
    background: #00ffa14f;
    padding: 11px 11px;
    border-radius: 10px;
    font-family: "GilroyGilroyLight";
}